<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div id="course-page">
    <vs-alert :active.sync="errorOccurred" color="danger" title="Course Not Found">
      <span>Course record with id: {{ $route.params.courseId }} not found. </span>
      <span> <span>Check </span><router-link :to="{ name: 'courses' }" class="text-inherit underline">Course Selector</router-link> </span>
    </vs-alert>

    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <div
          class="vx-card rounded-lg flex w-full p-4"
          style="
            background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
            background-size: cover;
          "
        >
          <div class="flex justify-between w-full">
            <div class="flex flex-wrap content-center pl-6 pr-6">
              <h1 class="mb-2 font-medium text-white w-full" style="font-size: 3rem" v-if="courseData">{{ courseData.name }}</h1>
              <h2 class="mb-2 font-light text-white w-full" v-if="courseData">
                {{ userData.firstName }} {{ userData.lastName }} - Course Report
              </h2>
            </div>
            <img
              :src="
                courseData.thumbnail !== null
                  ? courseData.thumbnail
                  : 'https://connect-cdn.intellectualpoint.com/assets/images/misc/default-placeholder.png'
              "
              class="rounded-lg hidden lg:block main-border"
              style="max-width: 150px; max-height: 150px; width: 20%"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-8">
      <div class="vx-col w-1/3">
        <vx-card>
          <div class="flex justify-between w-full">
            <h2 class="font-bold mb-1">Simulations</h2>
            <h4 class="font-light mb-2 main-bottom-border border-2">{{ courseCompletionData.simulation.simulations_percent }}% Complete</h4>
          </div>

          <div class="grid grid-cols-2 gap-3 gap-x-2 mt-3">
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Total Sims:
              <p class="font-medium">{{ courseCompletionData.simulation.simulations_total }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Completed Sims:
              <p class="font-medium">{{ courseCompletionData.simulation.simulations_completed }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Attempted Sims:
              <p class="font-medium">{{ courseCompletionData.simulation.simulations_attempted }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Incomplete Sims:
              <p class="font-medium">{{ courseCompletionData.simulation.simulations_incomplete }}</p>
            </h4>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-1/3">
        <vx-card>
          <div class="flex justify-between w-full">
            <h2 class="font-bold mb-1">FlashCards</h2>
            <h4 class="font-light mb-2 main-bottom-border border-2">{{ courseCompletionData.flashcard.sets_percent }}% Complete</h4>
          </div>

          <div class="grid grid-cols-2 gap-3 gap-x-2 mt-3">
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Total Sets:
              <p class="font-medium">{{ courseCompletionData.flashcard.sets_total }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Completed Sets:
              <p class="font-medium">{{ courseCompletionData.flashcard.sets_completed }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Started Sets:
              <p class="font-medium">{{ courseCompletionData.flashcard.sets_started }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Incomplete Sets:
              <p class="font-medium">{{ courseCompletionData.flashcard.sets_incomplete }}</p>
            </h4>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-1/3">
        <vx-card>
          <div class="flex justify-between w-full">
            <h2 class="font-bold mb-1">ExamEdge</h2>
            <h4 class="font-light mb-2 main-bottom-border border-2">{{ courseCompletionData.examedge.assignments_percent }}% Complete</h4>
          </div>

          <div class="grid grid-cols-2 gap-3 gap-x-2 mt-3">
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Total Attempts:
              <p class="font-medium">{{ courseCompletionData.examedge.attempts_total }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Test Attempts:
              <p class="font-medium">{{ courseCompletionData.examedge.attempts_test_mode }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Study Attempts:
              <p class="font-medium">{{ courseCompletionData.examedge.attempts_study_mode }}</p>
            </h4>
            <h4 class="flex justify-between w-full items-center gap-x-2 font-light bg-light p-2 rounded-lg main-border">
              Asgmt Progress:
              <p class="font-medium">
                {{ courseCompletionData.examedge.assignments_completed }}/{{ courseCompletionData.examedge.assignments_total }}
              </p>
            </h4>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row" id="UserViewCourseProgress">
      <div class="vx-col w-full">
        <vs-table :data="courseCompletionData.examedge.assignments_progress" max-items="6" search class="main-border rounded-lg">
          <template slot="header">
            <h3 class="ml-4">Assignments</h3>
          </template>

          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Mode</vs-th>
            <vs-th>Range Start</vs-th>
            <vs-th>Range End</vs-th>
            <vs-th>Questions</vs-th>
            <vs-th>Shuffle</vs-th>
            <vs-th>Timer (Min)</vs-th>
            <vs-th>Total Attempts</vs-th>
            <vs-th>Completed</vs-th>
            <vs-th>Best Score</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id">
              <vs-td :data="attach.name">
                {{ attach.name }}
              </vs-td>

              <vs-td :data="attach.mode">
                {{ attach.mode }}
              </vs-td>

              <vs-td :data="attach.rangeStart">
                {{ attach.rangeStart }}
              </vs-td>

              <vs-td :data="attach.rangeEnd">
                {{ attach.rangeEnd }}
              </vs-td>

              <vs-td :data="attach.questions">
                {{ attach.questions }}
              </vs-td>

              <vs-td :data="attach.shuffle">
                {{ attach.shuffle ? 'Yes' : 'No' }}
              </vs-td>

              <vs-td :data="attach.timer">
                {{ attach.timer }}
              </vs-td>

              <vs-td :data="attach.total_attempts">
                {{ attach.total_attempts }}
              </vs-td>

              <vs-td :data="attach.completed">
                <div class="flex items-center justify-center gap-3">
                  <feather-icon v-if="attach.completed" icon="CheckCircleIcon" svgClasses="h-6 w-6 text-success" />
                  <feather-icon v-else icon="XCircleIcon" svgClasses="h-6 w-6 text-danger" />
                  {{ attach.completed_attempts }} Times
                </div>
              </vs-td>

              <vs-td :data="attach.best_attempt">
                <div v-if="attach.best_attempt">{{ attach.best_attempt.percent }}%</div>
                <div v-else>N/A</div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import LottieAnimation from '../../../../../components/lottie/LottieAnimation';

export default {
  data() {
    return {
      courseData: null,
      userData: null,
      errorOccurred: false,

      courseCompletionData: {},
    };
  },
  components: {
    VueApexCharts,
    LottieAnimation,
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    skeletonColor() {
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgb(224, 224, 224)';
      if (theme === 'dusk') return 'rgb(5, 0, 53, 1)';
      if (theme === 'dark') return 'rgb(22, 23, 25)';
    },
    skeletonHighlight() {
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgb(248, 248, 248)';
      if (theme === 'dusk') return 'rgb(27 25 53)';
      if (theme === 'dark') return 'rgb(94 94 94 / 73%)';
    },
  },
  methods: {
    loadCourseProgress(id, uid) {
      this.$http
        .get(`courses/${id}/report?userID=${uid}`)
        .then((response) => {
          if (response.data.data) {
            this.courseCompletionData = response.data.data.report;
            this.courseData = response.data.data.course;
            this.userData = response.data.data.user;
          }
        })
        .catch(() => {});

      /*
                    this.courseSimulationChart = {
              data: [
                {
                  name: 'Not Started',
                  counts: response.data.data.simulations.count.not_started,
                  color: 'primary',
                },
                {
                  name: 'Started',
                  counts: response.data.data.simulations.count.started,
                  color: 'warning',
                },
                {
                  name: 'Completed ',
                  counts: response.data.data.simulations.count.completed,
                  color: 'success',
                },
              ],
              series: [
                response.data.data.simulations.count.not_started,
                response.data.data.simulations.count.started,
                response.data.data.simulations.count.completed,
              ],
            };
            for (const [type, value] of Object.entries(response.data.data.simulations.list)) {
              value.forEach((item) => {
                item.progress = type;
                this.courseSimulationTable.push(item);
              });
            }*/

      /*  this.$http.get(`progress/p/@me/courses/completion?id=${id}`)
          .then(response => {

            if (response.data.data) {

              this.course_completion_data = response.data.data.total_course_completion

              this.course_completion_chart = {
                analyticsData: [
                  {
                    'name': 'Not Started',
                    'counts': this.course_completion_data.not ? this.course_completion_data.not : 0,
                    'color': 'primary'
                  },
                  {
                    'name': 'Started',
                    'counts': this.course_completion_data.started ? this.course_completion_data.started : 0,
                    'color': 'warning'
                  },
                  {
                    'name': 'Completed ',
                    'counts': this.course_completion_data.completed ? this.course_completion_data.completed : 0,
                    'color': 'success'
                  }
                ],
                series: [
                  this.course_completion_data.not ? this.course_completion_data.not : 0,
                  this.course_completion_data.started ? this.course_completion_data.started : 0,
                  this.course_completion_data.completed ? this.course_completion_data.completed : 0
                ]
              }

            }

          })
          .catch(() => {
          })

        this.$http.get(`progress/p/@me/courses/simulation-chart?id=${id}`)
          .then(response => {

            if (response.data.data) {

              this.simulation_chart_series = [
                {
                  name: 'Started',
                  data: response.data.data.started
                }, {
                  name: 'Completed',
                  data: response.data.data.completed
                }
              ]
            }
          }).catch(() => {
          })

        this.$http.get(`progress/p/@me/courses/time-chart?id=${id}&tz=${timezoneOffset}`)
          .then(response => {

            if (response.data.data) {

              this.time_chart_series = [
                {
                  name: 'Time Practiced',
                  data: response.data.data.time
                }
              ]
            }
          }).catch(() => {
          }) */
    },
  },
  beforeMount() {
    this.loadCourseProgress(this.$route.params.courseId, this.$route.params.userId);
  },
  watch: {
    $route() {
      this.loadCourseProgress(this.$route.params.courseId, this.$route.params.userId);
    },
  },
};
</script>

<style lang="scss">
#UserViewCourseProgress {
  .vs-table--header {
    border-bottom: 1px solid #181d2a;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead tr {
    border-bottom: 1px solid #181d2a !important;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table tr {
    border-bottom: 1px solid #181d2a !important;
    border-radius: 0rem !important;

    &:last-child {
      border-bottom: none !important;
    }
  }

  .vs-pagination--nav .vs-pagination--buttons {
    background: #181d2a;
    color: #ffffff;
  }
}
</style>
